<template>
    <div class="my">
<!--        头部卡片-->
        <div class="my_top">
            <div class="my_top_info">
                <div class="my_top_info_head"><img :src="headImg"/></div>
                <div class="my_top_info_comppany">
                    <div class="company_name" :class="company_name_bord" v-on:click="tologin">{{nickName}}</div>
                    <div class="info" :class="islogin?'':'unmarginleft'">
                        <div class="icon" v-if="islogin"><img src="assets/company_tips.png"/></div>
                        <div class="types" v-if="islogin">技师</div>
                        <div class="types" :class="islogin?'':'unmarginleft'" v-else>登录后获取更多精彩内容</div>
                    </div>
                </div>
            </div>
            <div class="my_top_require">

                <div class="title">
                    <div class="p1">我的订单</div>
                    <div class="p2">
                        <div @click="toAllOrders">全部订单</div>
                        <img src="assets/go1.png"/></div>
                </div>

                <div class="sum">
                    <div class="item">
                        <span>待选标</span>
                        <span class="count">{{waitSelect}}</span>
                    </div>
                    <div class="item">
                        <span>待上门</span>
                        <span class="count">{{waitService}}</span>
                    </div>
                    <div class="item">
                        <span>已完成</span>
                        <span class="count">{{finished}}</span>
                    </div>
                </div>

            </div>
        </div>
<!--        菜单目录-->
        <div class="my_menu">
            <div class="item" v-on:click="toMyInfo">
                <div class="item_icon"><img src="assets/menu_icon_1.png"/></div>
                <div class="item_txt">个人信息</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter">
                <div class="item_icon"><img src="assets/menu_icon_3.png"/></div>
                <div class="item_txt">用户协议</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter" v-on:click="showPhone">
                <div class="item_icon"><img src="assets/menu_icon_4.png"/></div>
                <div class="item_txt">客服电话</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter">
                <div class="item_icon"><img src="assets/menu_icon_5.png"/></div>
                <div class="item_txt">关于篱笆</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
        </div>
<!--        退出系统-->
        <div class="my_button" v-if="islogin==true" @click="loginOut">
            <div>退出系统</div>
        </div>

<!--        上拉数据-->
        <Actionsheet v-model="actionVisible"
         :actions="actionData"
        ></Actionsheet>
    </div>
</template>

<script>
    // 作废，但是保留
    import Vue from 'vue';
    import {Actionsheet} from 'mint-ui';
    import keys from "../../xyg/utils/keys";
    import tools from "../../xyg/utils/tools";
    import baseUrl from "../../xyg/utils/constans";

    export default {
        name: "My",
        data(){
            return{
                headImg:'',//头像
                nickName:'',//昵称
                company_name_bord:'',//昵称样式
                islogin:false,
                actionVisible:false,
                waitSelect:'',
                waitService:'',
                finished:'',
                actionData:[
                    {
                        name:'客服电话：13382187048',
                        method:this.callPhone,
                    }
                ],
            }
        },
        mounted(){
            this.init();
        },
        components:{
            Actionsheet
        },
        methods:{
            toMyInfo(){
                // this.$router.push('JsMyInfo').catch((error)=>{console.log(error)});

                this.registerComponent().then(component=>{
                    let control={name:'JsMyInfo',component: new component()};
                    this.$emit('showJsMyInfo',control);
                });

            },
            callPhone(){
                let phone='13382187048'
                //拨打电话怒
                window.location.href='tel://'+phone;
            },
            showPhone(){
                this.actionVisible=true;
            },
            registerComponent() { //注册操作
                return import(`./JsMyInfo.vue`).then(component => {
                    return Vue.extend(component.default);
                });
            },
            toAllOrders(){
                //切换到订单选中
                this.$emit('changeTab','index_order');
            },
            init(){
                let open_id=localStorage.getItem(keys.openId);
                if(tools.StringIsBank(open_id)){ //未登录情况
                    //未登录，需要登录
                    //设置默认头像
                    this.headImg='assets/default_head.png';
                    //设置昵称
                    this.nickName='点击登录';
                    //设置昵称粗细
                    this.company_name_bord="company_name_bord";
                    //是否登录
                    this.islogin=false;
                    //订单统计数据
                    this.waitSelect='--';
                    this.waitService='--';
                    this.finished='--';
                }else{
                    this.islogin=true;
                    this.headImg=localStorage.getItem(keys.headUrl);
                    this.nickName=localStorage.getItem(keys.nickName);
                    this.waitSelect="0";
                    this.waitService="0";
                    this.finished="0";
                    //加载订单
                    this.loadOrder();
                }
            },
            tologin(){
                let redirect= encodeURIComponent(keys.webSite);
                let wxcodeUrl=keys.getWxCodeUrl.replace('{0}',redirect);
                console.log(wxcodeUrl);
                window.location.href=wxcodeUrl;
            },
            loadOrder(){
            },
            loginOut(){
                localStorage.removeItem(keys.headUrl);
                localStorage.removeItem(keys.nickName);
                localStorage.removeItem(keys.openId);
                localStorage.removeItem(keys.userType);
                //退出系统
                // this.$emit('changeTab','index_my');
                this.init();
            }
        }
    }
</script>

<style scoped>
    .my{
        display: flex;
        flex-direction: column;
        height: 100vh;
        flex:1;
    }

    .my_top{
        border-radius: 10px;
        background-color: white;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        width: 710px;
        margin-top:20px;
        margin-left:20px;
        margin-right: 20px;

    }

    /*我的信息*/
    .my_top_info{
        margin-left: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .my_top_info_head img{
        width: 100px;
        height: 100px;
        border-radius: 50px;
        /*box-shadow: 0px 2px 2px rgba(117,164,245,0.5);*/
    }
    .my_top_info_comppany{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 24px;
    }

    .company_name{
        font-size: 26px;
        letter-spacing: 2px;
    }

    .company_name_bord{
        font-weight: bold;
    }

    .unmarginleft{
        margin-left: unset !important;
    }

    .my_top_info_comppany .info{
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        margin-left: 4px;
        font-size: 22px;
        color:#A79F9F;
    }

    .my_top_info_comppany .info .icon img {
        width: 14px;
        height: 18px;
    }

    .my_top_info_comppany .info .types{
        margin-left: 8px;
    }

    /*我的需求样式*/
    .my_top_require{
        width: 710px;
        border-top: 2px #F1F1F1 solid;
    }

    .my_top_require .title{
        margin-top: 12px;
        margin-left: 40px;
        margin-right: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        letter-spacing: 2px;
    }
    .my_top_require .title .p1{
        font-size: 28px;
        flex: 1 auto;
        font-weight: bold;
    }
    .my_top_require .title .p2{
        font-size: 22px;
        flex: 1 auto;
        color: #A79F9F;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .p2 span{
        width: 96px;
    }

    .p2 img{
        width: 24px;
        height: 24px;
    }

    .my_top_require .sum{
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 16px;
        margin-top: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        height: 110px;
    }

    .sum .item{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        letter-spacing: 2px;
        font-size: 26px;
        color: #272525;
    }

    .item .count{
        color:#C50000;
        font-size: 24px;
        font-weight: bold;
        margin-top: 12px;
    }

    .my_menu{
        margin-top: 30px;
        border-radius: 10px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .my_menu .item{
        display: flex;
        flex-direction: row;
        height: 100px;
        align-items: center;
    }

    .my_menu .item_spliter{
        border-top: 4px #F6F6F6 solid;
    }

    .my_menu .item .item_icon{
        margin-left: 40px;
        height: 40px;
        width: 40px;
    }

    .my_menu .item .item_icon img{
        width: 40px;
        height: 40px;
    }

    .my_menu .item .item_txt{
        font-size: 28px;
        color: #797979;
        letter-spacing: 2px;
        margin-left: 30px;
        flex: 1;
    }

    .my_menu .item .item_goto2{
        height: 40px;
        margin-right: 40px;
    }

    .my_menu .item .item_goto2 img{
        width: 40px;
        height: 40px;
    }

    .my_button{
        margin-top: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .my_button div{
        height: 100px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 26px;
        background-color: #9C0000;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        letter-spacing: 4px;
        color: white;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>
